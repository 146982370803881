/**
 * @description スクロールでヘッダー色変更
 */

export function setHeaderChange(): void {
  let header = $('.js-headerTop').height();

  $(window).scroll(function () {
    let top = $(window).scrollTop();
    if (header < top) {
      $('.header-top,.header-logo1,.header-logo2').addClass('scroll');
    } else {
      $('.header-top,.header-logo1,.header-logo2').removeClass('scroll');
    }
  });
}
