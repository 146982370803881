/**
 * @description お問い合わせフォームのタブ切り替え
 */

export function setFormTab(): void {
  $('.js-tab a').click(function () {
    $(this).parent().addClass('active').siblings('.active').removeClass('active');
    let tabContents = $(this).attr('href');
    $(tabContents).addClass('active').siblings('.active').removeClass('active');
    return false;
  });
}
