/**
 * @description 閲覧デバイスがスマホであるか判定
 * @returns {boolean} 閲覧デバイスがタブレットであればtrue、それ以外はfalse
 */
export function isTablet(): boolean {
  var ua: string = navigator.userAgent;
  var ip: boolean = ua.indexOf('iPad') > 0;
  var ad: boolean = ua.indexOf('Android') > 0 && ua.indexOf('Mobile') == -1;
  return ip || ad;
}
