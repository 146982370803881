/**
 * こちらのファイルがビルドされて、common\js\build.jsに吐き出されます。
 * 各モジュール(src\js\modules内のファイル)は、こちらから読み込んでください。
 *
 * 例) スムーススクロールモジュール読み込み、実行
 * import SmoothScroll from './modules/smoothScroll';
 * smoothScroll();
 */

// import { smoothScroll } from './modules/smoothScroll';
import { isSumaho } from './modules/isSumaho';
import { isTablet } from './modules/isTablet';
import { getRelPath } from './modules/getRelPath';
import { setResponsiveImg } from './modules/setResponsiveImg';
import { setSpMenu } from './modules/setSpMenu';
import { setMatchHeight } from './modules/setMatchHeight';
import { setCheckBox } from './modules/setCheckBox';
import { setFormTab } from './modules/setFormTab';
import { setHeaderChange } from './modules/setHeaderChange';

setMatchHeight();
// smoothScroll();
setResponsiveImg();
isSumaho();
isTablet();
getRelPath();
// console.log('isSumaho実行結果:' + isSumaho());
// console.log('isTablet実行結果:' + isTablet());
// console.log('ルートからの相対パス:' + getRelPath());
setSpMenu();
setCheckBox();
setFormTab();
setHeaderChange();
