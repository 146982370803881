/**
 * @description 閲覧デバイスがスマホであるか判定
 * @returns {boolean} スマホであればtrue,それ以外はfalse
 */
export function isSumaho(): boolean {
  var ua: string = navigator.userAgent;
  var ip: boolean = (ua.indexOf('iPhone') > 0 && ua.indexOf('iPad') == -1) || ua.indexOf('iPod') > 0;
  var ad: boolean = ua.indexOf('Android') > 0 && ua.indexOf('Mobile') > 0;
  var etc: boolean = ua.indexOf('Windows Phone') > 0 || ua.indexOf('BlackBerry') > 0;
  return ip || ad || etc;
}
