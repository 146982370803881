import 'core-js/stable';
const Suger = require('sugar');

/**
 * @description 閲覧ページファビコンのパス返却
 * @returns {string} 閲覧ページファビコンの相対パス
 */
export function getRelPath(): string {
  var href: string = $('link[rel="icon"]').attr('href');
  var relpath = new Suger.String(location.pathname);

  if (href.startsWith('http')) {
    relpath = relpath.remove(/[a-z0-9\-\.]/g);
    relpath = relpath.remove(/^\//);
    relpath = relpath.replace(/\//g, '../');
  } else {
    href.match(/^([\./]+)/);
    relpath = RegExp.$1;
  }

  return relpath;
}
