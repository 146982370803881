import { isTablet } from '../modules/isTablet';
import { isSumaho } from '../modules/isSumaho';

/**
 * @description 各デバイス用(PC,タブレット,スマホ)imgタグ レスポンシブイメージ処理 (例)<img src="" data-pcimg="./img/pc.jpg" data-tabletimg="./img/tablet.jpg" data-sumahoimg="./img/sumaho.jpg" class="responsiveImg" alt="" />
 * @returns void
 */
export function setResponsiveImg(): void {
  $('img.responsiveImg').each(function () {
    var img: JQuery = $(this);
    var data: JQuery.PlainObject = img.data();
    var srcPath: string = '';
    if (img.attr('src') !== '') console.error('.responsiveImgのsrc属性に値を入力しないでください。');
    if (data.pcimg === '') console.error('data-pcimg属性にPC表示用の画像パス設定してください。');
    if (data.pcimg === undefined) console.error('PC用画像パスをdata-pc属性に設定してください。');
    if (data.tabletimg === '') console.error('data-tabletimg属性にタブレット表示用の画像パス設定してください。');
    if (data.tabletimg === undefined) console.error('タブレット用画像パスをdata-tablet属性を設定してください。');
    if (data.sumahoimg === '') console.error('data-sumahoimg属性にスマホ表示用の画像パス設定してください。');
    if (data.sumahoimg === undefined) console.error('スマホ用画像パスをdata-sumaho属性を設定してください。');
    if (isSumaho()) {
      srcPath = data.sumahoimg;
    } else if (isTablet()) {
      srcPath = data.tabletimg;
    } else {
      srcPath = data.pcimg;
    }
    if (srcPath) img.attr({ src: srcPath });
  });
}
