/**
 * @description spメニューの開閉
 */

export function setSpMenu(): void {
  if ($('body').width() <= 994) {
    $('.js-spMenuOpen,.gnav-link').on('click', () => {
      $('.hamburger,.header,.header-top,.header-logo').toggleClass('active');
      $('.gnav').stop().fadeToggle();
    });
  }
}
